import React from 'react';
// import logo from './logo.svg';
import './App.css';
import Console from './components/partials/Console'

const App: React.FC = () => {
  return (
    <div className="App">
      
        <Console></Console>
        
      
    </div>
  );
}

export default App;
