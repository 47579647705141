import React from 'react';
import ConsolePrefix from './ConsolePrefix';
import ConsoleTextChild from './ConsoleTextChild';
import CommandText from './CommandText';
import reactElementToJSXString from 'react-element-to-jsx-string';


const files: any = {
    "about.txt": <>
    
        Hi I'm Mick de Graaf. Husband, father, entrepreneur and blockchain engineer. <br />
        I'm the cofounder of DEFIED.io a decentralised asset management platform build on Ethereum.

    </>,
    "contact.txt": <>
        You can contact me on <a href="https://twitter.com/mickdeg010">Twitter</a>, <a href="https://www.linkedin.com/in/mick-graaf-26240bb5/">Linkedin</a> or send me an email at <a href="mailto:mick@mickdegraaf.nl">mick@mickdegraaf.nl</a>
    </>,
    // "experience": {
    //     "defied.txt": "Defied is cool",
    //     "cryptopepes.txt": "Cool Collectible",
    // }
    
}

class Console extends React.Component {

    state = {
        currentPath: "",
        currentDirectory: files,
        lastCommand: "",
        consoleChilds: [
            // <ConsoleTextChild text="Welcome! Type the ls command for available files" />
        ]
    }

    componentDidMount() {
        // this.pushText(" lk");
        this.pushText(
            <div>
                Welcome! type the <span onClick={this.handleClickCommand('ls')} className="command">ls</span> command to get started. Otherwise type the <span onClick={this.handleClickCommand('help')} className="command">help</span> command for available commands. You can also click/tap all commands, files and directories on screen.
            </div>
        );
    }

    render() {
        //console.log(this.state);
        return (
            <div className="console">
                <div className="">
                    {this.state.consoleChilds}
                </div>  

                <div className="consoleInput">
                    <ConsolePrefix></ConsolePrefix>
                    <input type="text" onKeyDown={this.keyDown} onKeyPress={this.keyPress} />
                </div>
        
            </div>
        )
    }


    handleClickCommand = (value: string) => () => {
        this.handleCommand(value);
    }

    handleCommand = (value: string) => {
        // add command input
        this.setState((prevState: any) => ({
            consoleChilds: [
                ...prevState.consoleChilds,
                <CommandText text={value} />
            ],
            lastCommand: value
        }))


        const command = value.trim().split(" ")[0];

        if(this.commands[command]) {
            this.commands[command](value);
        } else {
            this.pushText("command not found");
        }

        window.scrollTo(0,document.body.scrollHeight);
    }

    keyPress = (event: any) => {
        if(event.key === "Enter") {
            //console.log(event.target.value);
            this.handleCommand(event.target.value);
            event.target.value = "";
        } 
        ////console.log(event.keyCode);


        window.scrollTo(0,document.body.scrollHeight + 100000000);
        
    }

    keyDown = (event: any) => {
       if(event.key == "ArrowUp") {
            event.target.value = this.state.lastCommand
       }
    }

    handleLs = (input:string) => {
        
        let keys:any = Object.keys(this.state.currentDirectory);

        keys = keys.map((key:any) => (
            <span onClick={key.indexOf('.txt') !== -1 ? this.handleClickCommand(`cat ${key}`) : this.handleClickCommand(`cd ${key}`)} className="command-pad-right">{key}</span>
        ))

        // const text = keys.join("\u00A0 \u00A0 \u00A0");
        this.pushText(keys);    
    }

    handleHelp = () => {

        this.pushText(
            <>
                <span onClick={this.handleClickCommand('ls')} className="command">ls</span> - List files and directories in the current directory <br />
                <span onClick={this.handleClickCommand('cd')} className="command">cd</span> - Changes directory. Example: "cd path" <br />
                <span onClick={this.handleClickCommand('cat')} className="command">cat</span> - Outputs a text file on the console. Example: "cat about.txt" <br />
            </>
        )

    }

    handleCat = (input: string) => {
        if(input.split(" ")[1] === undefined) {
            this.pushText("File not found");
            return;
        }
        const files = this.state.currentDirectory;
        const file = input.split(" ")[1].trim();

        //console.log(files[file]);
        //console.log(file);

        if(files[file] !== undefined) {
            this.pushText(files[file]);
        }
    }

    handleCd = (input: string) => {
        if(input.split(" ")[1] === undefined) {
            this.pushText("Directory not found");
            return;
        }
        const directory = input.split(" ")[1].trim();
        //console.log(directory);

        const path = this.state.currentPath.split("/");
        const futurePath = [...path, directory];
        //console.log(futurePath);

        if(futurePath[0] === "") {
            futurePath.splice(0,1);
        }

        if(futurePath[0] !== "" && directory === "../") {
            futurePath.pop();
        }
        //console.log(futurePath);
        
        // const currentDir = this.getCurrentDir(path);
        const futureDir = this.getCurrentDir(futurePath);

        if(futureDir.error) {
            this.pushText("No such file or directory");
            return;
        }

        //console.log(futureDir);

        this.setState({
            currentDirectory: futureDir
        })

        // //console.log(currentDir);
        //console.log(futureDir);
        
    }

    getCurrentDir(path:string[]) {
        //console.log(path);

        let currentdir = files;

        if(path.length === 1 && path[0] === "") {
            return currentdir;
        } else {
            for(let dir of path ) {
                //console.log(dir);
                if(currentdir[dir] !== undefined) {
                    currentdir = currentdir[dir];
                }
                else{
                    return {error: true}
                }
            }

            return currentdir
        }
    }

    handleClear = (value: string) => {
        this.setState({
            consoleChilds: []
        })
    }

    pushText = (value: string | any) => {
        this.setState((prevState: any) => ({
            consoleChilds: [
                ...prevState.consoleChilds,
                <ConsoleTextChild>{value}</ConsoleTextChild>
            ]
        }))
    }

    commands: any = {
        "ls" : this.handleLs,
        "cat": this.handleCat,
        "cd": this.handleCd,
        "clear": this.handleClear,
        "help": this.handleHelp,
    }

}

export default Console