import React from 'react';

class ConsolePrefix extends React.Component {

    render() {
        return(
            <span className="consolePrefix">guest@mickdegraaf.nl: ~$</span>
        )
    }
}

export default ConsolePrefix;